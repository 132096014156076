import React, { useState, useRef, useEffect } from 'react';
import './Footer.css';

const Footer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [isMapPopupOpen, setIsMapPopupOpen] = useState(false); // New state for the map popup
  const popupRef = useRef(null);
  const contactFormRef = useRef(null);
  const mapPopupRef = useRef(null);

  // Function to handle clicking outside the popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false); // Close popup if clicked outside
      }
      if (contactFormRef.current && !contactFormRef.current.contains(event.target)) {
        setIsContactFormOpen(false); // Close contact form popup if clicked outside
      }
      if (mapPopupRef.current && !mapPopupRef.current.contains(event.target)) {
        setIsMapPopupOpen(false); // Close map popup if clicked outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to open the call popup
  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  // Function to open the contact form popup
  const handleContactFormOpen = () => {
    setIsContactFormOpen(true);
  };

  // Function to open the map popup
  const handleMapPopupOpen = () => {
    setIsMapPopupOpen(true);
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section-about">
          <div className="contact">
            {/* Phone button to open the popup */}
            <button className="link-button" onClick={handlePopupOpen}>
              <i className="fas fa-phone"></i>&nbsp; +233 53 454 4454
            </button>
            {/* Email button to open the contact form popup */}
            <button className="link-button" onClick={handleContactFormOpen}>
              <i className="fas fa-envelope"></i>&nbsp; chemlab.app@gmail.com
            </button>
            {/* Location button to open the map popup */}
            <button className="link-button" onClick={handleMapPopupOpen}>
              <i className="fas fa-map-marker-alt"></i>&nbsp; Accra, Ghana
            </button>
            {/* Link button for privacy page */}
            <button className="link-button" onClick={() => window.location.href = '/privacy-policy'}>
              <i className="fas fa-lock"></i>&nbsp; Privacy Policy
            </button>

          </div>
        </div>
      </div>

      <hr />
      &copy; 2024 Chem <i className="fas fa-flask footflask"></i>
      <span className="spanfoot">Lab</span>

      {/* Popup Modal for Call */}
      {isPopupOpen && (
        <div className="footer-popup-overlay">
          <div className="footer-popup" ref={popupRef}>
            <h3>Reach out to us</h3>
            <div className="popup-buttons">
              <a href="tel:+233534544454" className="popup-btn call-btn">
                <i className="fas fa-phone"></i>&nbsp; Call Us
              </a>
              <a href="https://wa.me/233534544454" target="_blank" rel="noopener noreferrer" className="popup-btn whatsapp-btn">
                <i className="fab fa-whatsapp"></i>&nbsp; WhatsApp Us
              </a>
              <button className="popup-btn close-btn" onClick={() => setIsPopupOpen(false)}>
                <i className="fas fa-times"></i>&nbsp; Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Popup Modal for Contact Form */}
      {isContactFormOpen && (
        <div className="footer-popup-overlay">
          <div className="footer-popup" ref={contactFormRef}>
            <h3>Contact Us</h3>
            <form className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" rows="4" required></textarea>
              </div>
              <button type="submit" className="popup-btn send-btn">Send Message</button>
              <button type="button" className="popup-btn close-btn" onClick={() => setIsContactFormOpen(false)}>
                <i className="fas fa-times"></i>&nbsp; Close
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Popup Modal for Map Options */}
      {isMapPopupOpen && (
        <div className="footer-popup-overlay">
          <div className="footer-popup" ref={mapPopupRef}>
            <h3>Choose Action</h3>
            <div className="popup-buttons">
              <button
                className="popup-btn map-btn"
                onClick={() => {
                  window.open('https://maps.app.goo.gl/vjXPCjceysvijGyT7', '_blank');
                  setIsMapPopupOpen(false);
                }}
              >
                <i className="fas fa-map-marker-alt"></i>&nbsp; Open in Google Maps
              </button>
              <button
                className="popup-btn web-btn"
                onClick={() => {
                  window.open('https://www.google.com/search?q=Accra+Ghana', '_blank');
                  setIsMapPopupOpen(false);
                }}
              >
                <i className="fas fa-search"></i>&nbsp; Search on the Web
              </button>
              <button className="popup-btn close-btn" onClick={() => setIsMapPopupOpen(false)}>
                <i className="fas fa-times"></i>&nbsp; Close
              </button>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
