import React from 'react';
import errorImage from '../../../Assets/404.svg';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="notfound-container">
      <div className="notfound-image">
        <img src={errorImage} alt="astronautimage" />
      </div>
      <div className="notfound-text">
        <h1>Page not found</h1>
        <p>
          Oops! Looks like you followed a bad link. <br />If you think this is a problem with us, please tell us.
        </p>
        <a href="/" className="notfound-button">
          <svg className="notfound-icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
          Go back home
        </a>
      </div>
    </div>
  );
};

export default NotFound;