import React from 'react'
import privacy from '../../../Assets/ChemLab_Privacy_Policy.pdf'
const policy = () => {
  return (
	<div className="organic-page">
		<div className="organic-head">
			<h2>Privacy Policy</h2>
			<div className="organic-content">
				<iframe  width="100%"
              height="600px" src={privacy} title="ChemLab Privacy Policy"></iframe>
			</div>
		</div>
	</div>
  )
}

export default policy