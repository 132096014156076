import './App.css';
import { Routes, Route } from 'react-router-dom'; // Import Routes and Route
import NavBar from './Components/NavBar/NavBar'; // Import NavBar
import Footer from './Components/Footer/Footer'; // Import Footer
import Home from './Components/Pages/Home/Home'; // Correct path to Home page
import Access from './Components/Pages/Access/Access'; // Correct path to Access page
import Iupac from './Components/Pages/IUPAC/Iupac'; // Correct path to IUPAC Rules page
import Alkenes from './Components/Pages/Alkenes/Alkenes'; // Correct path to Alkenes page
import Alkanes from './Components/Pages/Alkanes/Alkanes'; // Correct path to Alkanes page
import Alkynes from './Components/Pages/Alkynes/Alkynes'; // Correct path to Alkynes page
import Alkanols from './Components/Pages/Alkanols/Alkanols'; // Correct path to Alkanols page
import Alkanoic from './Components/Pages/Alkanoic/Alkanoic'; // Correct path to Alkanoic Acids page
import Developers from './Components/Pages/Developers/Developers'; // Correct path to Developers page
import Policy from './Components/Pages/Policy/policy'; // Correct path to Privacy Policy page
import NotFound from './Components/Pages/NotFound/NotFound'; // 404 page for when a page is not found
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome for icons
import { SpeedInsights } from "@vercel/speed-insights/react"; // Import SpeedInsights for performance tracking
import { Analytics } from "@vercel/analytics/react"


function App() {
  return (
    <div className='App'>
      <NavBar /> {/* NavBar will appear on all pages */}
      
      {/* Speed Insights for performance analysis */}
      <SpeedInsights />
      <Analytics/>
      <div className='content'>
        <Routes>
          {/* Define routes for each page */}
          <Route path="/" element={<Home />} />
          <Route path="/access" element={<Access />} />
          <Route path="/alkanes" element={<Alkanes />} />
          <Route path="/alkenes" element={<Alkenes />} />
          <Route path="/alkynes" element={<Alkynes />} />
          <Route path="/alkanols" element={<Alkanols />} />
          <Route path="/privacy-policy" element={<Policy />} />
          {/* Correct the path for Alkanoic Acids */}
          <Route path="/alkanoic-acids" element={<Alkanoic />} /> 
          <Route path="/iupac" element={<Iupac />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>

      <Footer /> {/* Footer will appear on all pages */}
    </div>
  );
}

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
            console.log('Service Worker registration failed:', error);
        });
}
export default App;

